.header .headerMainnav .backNav {
    background: #000000 0% 0% no-repeat padding-box;
}

.header .headerMainnav .headlogo {
    width: 100%;
    height: 57px;
    object-fit: contain;
}

.header .headerMainnav .rightMainsec {
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    padding: 5px 15px;
    margin-right: 10px;
}

.header .headerMainnav .rightMainsectwo {
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    padding: 7px 0px;
    margin-right: 10px;
}

.header .headerMainnav .timesecmain .timeinfo {
    color: red;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    padding: 4px;
}

.header .headerMainnav .timesecmain .timeinfo p {
    margin-bottom: 0;
    font-weight: 600;
}

.header .headerMainnav .timesecmain div:nth-of-type(2) {
    color: #fff;
    font-size: 13px;
    margin-top: 3px;
    text-align: center;
}

.header .headerMainnav .timesecmain .timeinfocircle {
    /* padding: 5px 8px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    width: 35px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.p-inputtext {
    padding: 5px;
    border: 0;
    border-radius: 50%;
}

.header .headerMainnav .rightMainrow {
    margin: 7px 0px;
}

.header .headerMainnav .timesecmain .iconImg {
    width: 100%;
    height: 17px;
    object-fit: contain;
    position: absolute;
    z-index: 9;
}

.header .headerMainnav .barIcon {
    color: #fff !important;
    display: none;
}

.p-datepicker .p-datepicker-header {
    background: #004992 0% 0% no-repeat padding-box !important;
    color: #fff !important;
}

.p-datepicker-title button {
    color: #fff;
}

.p-datepicker td {
    border: 1px solid #ccc;
}

.p-datepicker th {
    background: #004992 0% 0% no-repeat padding-box;
    border-radius: 2px;
    color: #fff;
    text-align: center;
}

.desktopHeader {
    display: block;
}

.mobileHeader {
    display: none;
}
.nextgameinp{
    color: #fff;
    margin-right: 20px;
    margin-bottom: 0px;
}
@media screen and (min-width: 0px) and (max-width: 767.98px) {
    .header .headerMainnav .timesecmain div:nth-of-type(2) {
        margin-top: 0px;
    }

    .Mainhometabs .homeMain .white-bgggggg {
        padding-top: 0px;
    }

    .header .headerMainnav .rightMainsectwo {
        padding: 0px 0px;
        margin-right: 0px;
    }

    .header .headerMainnav .headlogo {
        height: 35px;
    }

    .header .headerMainnav .backNav .mobileNavvv {
        display: none !important;
    }

    .header .headerMainnav .barIcon {
        display: block;
    }

    .p-inputtext {
        padding: 0px;
    }

    .desktopHeader {
        display: none;
    }

    .mobileHeader {
        display: block;
    }

    .header .headerMainnav .rightMainsec {
        border: 0;
        border-radius: 0;
        padding: 5px 15px;
        margin-right: 0;
        background: #000;
    }

    .header .headerMainnav .timesecmain .timeinfocircle {
        width: 25px;
        height: 25px;
    }

    .header .headerMainnav .timesecmain .iconImg {
        height: 11px;
    }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .header .headerMainnav .backNav {
        padding-bottom: 38px;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
    .header .headerMainnav .backNav {
        padding-bottom: 38px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .header .headerMainnav .backNav {
        padding-bottom: 38px;
    }
}