.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    /* height: 770px; */
}

.table-responsive p {
    margin-bottom: 0px;
}

@media screen and (min-width: 0px) and (max-width: 320px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 589px; */
    }
}

@media screen and (min-width: 321px) and (max-width: 485px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 589px; */
    }
}

@media screen and (min-width: 486px) and (max-width: 575px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 673px; */
    }
}


@media screen and (min-width: 576px) and (max-width: 767px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 617px; */
    }
}


@media screen and (min-width: 768px) and (max-width: 991px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 673px; */
    }
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 637px; */
    }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 660px; */
    }
}

/* @media screen and (min-width: 1600px) and (max-width: 1599px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        height: 700px;
    }
} */
@media screen and (min-width: 1200px) and (max-width: 1299px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 1075px; */
    }
}

@media screen and (min-width: 1300px) and (max-width: 1399px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 1019px; */
    }
}

@media screen and (min-width: 1400px) and (max-width: 1499px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 700px; */
    }
}

@media screen and (min-width: 1500px) and (max-width: 1599px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 1075px; */
    }
}

@media screen and (min-width: 1600px) and (max-width: 1699px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 1073px; */
    }
}

@media screen and (min-width: 1700px) and (max-width: 1799px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 706px; */
    }
}

@media screen and (min-width: 1800px) and (max-width: 1899px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 706px; */
    }
}

@media screen and (min-width: 1900px) and (max-width: 1999px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 782px; */
    }
}

@media screen and (min-width: 2000px) and (max-width: 3000px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        /* height: 1050px; */
    }
}