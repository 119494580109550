.charttab .tableclassone thead tr {
    border-collapse: collapse;
}

.Mainhometabs {
    position: relative;
    /* height: 80vh;
    min-height: 100vh;
    object-fit: cover;
    margin-bottom: -7px; */
}

.charttab .homeMain .homeNav .nav-link {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 19px;
    font: normal normal bold 18px/21px Arial;
    letter-spacing: 0px;
    color: #FF0000;
}

.charttab .homeMain .homeNav .nav-link.active {
    background: #278F02 0% 0% no-repeat padding-box;
    border-radius: 19px;
    color: #fff;
}

.charttab .homeMain .homeNav {
    justify-content: center;
    background: transparent linear-gradient(90deg, #102530 20%, #0a1c25 51%, #000000FA 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: -31px;
    left: 50%;
    transform: translate(-50%, 0px);
    border-radius: 30px;
    padding: 13px 19px;
}

.charttab .homeMain .white-bgggggg {
    text-align: center;
    padding-top: 40px;
    opacity: 1;
    margin-bottom: 5px;
}

.charttab .homeMain .white-bgggggg p {
    color: #000;
    background: linear-gradient(90deg, #80808000 0%, rgba(230, 230, 230, 1) 25%, rgba(241, 241, 241, 1) 50%, rgba(255, 255, 255, 1) 70%, #80808000 100%);
    width: 50%;
    font-size: 18px;
    font-weight: bold;
    margin: auto;
}

.charttab .homeMain .tabsss {
    margin-right: 10px;
}

.charttab .tableclassone {
    margin-top: 20px;
    margin-bottom: 0;
}

.charttab .tableclassone thead tr th {
    background-color: #FF0000;
}



.charttab .tableMain .tableflex {
    display: flex;
    justify-content: space-around;
    background: red;
    color: #000;
    /* padding: 7px 7px; */
    margin-top: 12px;
    margin-left: 9px;
    margin-right: 9px;
    overflow: auto;
    flex-wrap: nowrap;
}

.charttab .tableMain .nav-pills .nav-link {
    color: #fff;
    font-size: 20px;
}

.charttab .tableMain .tableflex .tableNo {
    font: normal normal bold 22px/26px Arial;
    text-align: center;
}

.charttab .tableMain .tableflex .nav-pills .nav-link.active {
    background: #fff !important;
    color: #000 !important;
}

.charttab .tableMain .tableflex .nav-pills .nav-link {
    background: transparent !important;
    border: 1px solid #FFFFFF;
    border-radius: 0px !important;
    color: #fff !important;
    font: normal normal bold 22px/26px Arial !important;
    padding: 2px 40px;
}


.charttab .tablechartmobile {
    display: none;
}

.charttab .tableMain {
    display: block;
}


.charttab .tableMain th,
.charttab .tableMain td {
    text-align: center;
    border-bottom: 0;
    font: normal normal bold 16px/16px Arial;
    letter-spacing: 0px;
    color: #000;
    text-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
}

.charttab .tableMain table {
    border-collapse: separate;
    border-spacing: 10px;
}

.charttab .tablechartmobile table {
    /* border-collapse: separate; */
    /* border-spacing: 10px; */
}

.charttab .tableMain tbody tr:nth-child(odd) td {
    background: #fff;
    font: normal normal bold 20px/29px Arial;
    color: #000;
    text-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    border: 1px solid #FFFFFF;
}

.charttab .tableMain tbody tr:nth-child(even) td {
    background: #004992 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font: normal normal bold 20px/29px Arial;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
}

.charttab .tableMain table tbody td:first-child {
    padding: 10px 0px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
}


@media screen and (min-width: 0px) and (max-width: 767.98px) {

    .tablemainMobile {
        background-color: transparent;
        /* border-collapse: separate; */
        /* border-spacing: 5px; */
    }

    .charttab .homeMain .white-bgggggg p {
        font: normal normal bold 13px/9px Arial;
        padding: 7px 0px 7px 0px;
        margin-bottom: 12px;
        width: 75%;
    }

    .charttab .homeMain .homeNav .nav-link {
        font: normal normal bold 14px/21px Arial;
        padding: 3px 15px;
    }

    .charttab .homeMain .homeNav {
        padding: 9px 6px;
        width: 100%;
        position: unset;
        transform: none;
    }

    /* .charttab .tableflex {
        overflow: auto;
        width: 100%;
        flex-wrap: nowrap;
        margin-left: 0;
    } */

    .charttab .tableflex {
        overflow: auto;
        width: 100%;
        flex-wrap: nowrap;
        margin-left: 0;
        display: flex;
        justify-content: space-around;
        background: red;
        color: #000;
        padding: 7px 7px;
        margin-top: 12px;
        overflow: auto;
        flex-wrap: nowrap;
    }



    .charttab .nav-pills .nav-link {
        font-size: 12px !important;
        font-weight: 500;
        padding: 0px 5px;
        margin-right: 5px;
        color: #fff;
    }

    .charttab .tableMain {
        display: none;
    }

    .charttab .tablechartmobile {
        display: block;
        padding: 7px;
    }

    .tablemainthree {
        margin-top: 5%;
    }


    .charttab .tablechartmobile th,
    .charttab .tablechartmobile td {
        text-align: center;
        background: #004992 0% 0% no-repeat padding-box;
        border-bottom: 0;
        font-size: 10px !important;
        font-weight: 600;
        letter-spacing: 0px;
        color: #fff;
        text-shadow: 0px 3px 6px #00000029;
        padding: 8px 1px;
        border: 1px solid #000;
        /* border-radius: 5px; */
    }

    .charttab .tablechartmobile tbody tr:nth-child(odd) td {
        background: #fff;
        font-size: 10px !important;
        font-weight: 600;
        color: #000;
        text-shadow: 0px 3px 6px #00000029;
        padding: 8px 1px;
        border: 1px solid #000;
        /* border-radius: 5px; */
    }

    .charttab .tablechartmobile tbody tr:nth-child(even) td {
        background: #004992 0% 0% no-repeat padding-box;
        font-size: 10px !important;
        color: #FFFFFF;
        text-shadow: 0px 3px 6px #00000029;
        padding: 8px 1px !important;
        border: 1px solid #000;
        /* border-radius: 5px; */
    }

    .charttab .tablechartmobile table tbody td:first-child {
        padding: 8px 0px;
        margin-bottom: 0;
        font-weight: 600;
    }


}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .charttab .tableflex {
        overflow: auto;
        width: 100%;
        flex-wrap: nowrap;
    }

    .charttab .nav-pills .nav-link {
        font: normal normal bold 14px/26px Arial !important;
        padding: 4px 19px;
        margin-right: 11px;
    }

    .charttab .tableMain tbody tr:nth-child(odd) td {
        font: normal normal bold 17px/29px Arial;
    }

    .charttab .tableMain tbody tr:nth-child(even) td {
        font: normal normal bold 17px/29px Arial;
    }

    .charttab .homeMain .homeNav .nav-link {
        font: normal normal bold 14px/21px Arial;
    }

    .charttab .homeMain .homeNav {
        padding: 9px 16px;
    }
}

@media(min-width:0px) and (max-width:767px) {
    .Mainhometabs {
        height: 78vh;
        min-height: 70vh;
        object-fit: cover;
        margin-bottom: -7px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .Mainhometabs {
        height: 80vh;
        min-height: 84vh;
        object-fit: cover;
        margin-bottom: -7px;
    }
}

@media(min-width:1400px) and (max-width:1600px) {
    .Mainhometabs {
        height: 80vh;
        min-height: 86vh;
        object-fit: cover;
        margin-bottom: -7px;
    }
}