.singletab .tableMain th,
.singletab .tableMain td {
    text-align: center;
    border-bottom: 0;
    font: normal normal bold 15px / 14px Arial;
    letter-spacing: 0px;
    color: #000;
    text-shadow: 0px 3px 6px #00000029;
}

.singletab .tableMain .tablemaintwo {
    border-collapse: separate;
    border-spacing: 10px;
    margin-bottom: 0%;
}

.singletab .tableMain tbody tr:nth-child(even) td:not(:first-child) {
    background: #fff;
    font: normal normal bold 14px / 14px Arial;
    color: #000;
    text-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
}

.singletab .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
    background: #004992 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font: normal normal bold 14px / 14px Arial;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
}

.singletab .tableMain .tablemaintwo tbody td:first-child {
    padding: 15px 0px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    text-wrap: nowrap;
}

.singletab .tableMain {
    display: block;
}

.singletab .tableMainmobile {
    display: none;
}




/* @media screen and (min-width: 768px) and (max-width: 1200px) {

    .singletab .tableMain th,
    .singletab .tableMain td {
        font: normal normal bold 14px/29px Arial;
    }

    .singletab .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }

    .singletab .tableMain tbody tr:nth-child(even) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }
} */


@media screen and (min-width: 0px) and (max-width: 767.98px) {

    .singletab .tableMain .tablemaintwo tbody td:first-child {
        padding: 6px 0px;
    }

    .singletab .tableMain {
        display: none;
    }

    .singletab .tableMainmobile {
        display: block;
    }

    .singletab .tablemainMobile tr td {
        font-size: 10px;
        font-weight: 600;
        /* padding: 8px 2px; */
        padding: 0px;
        text-align: center;
        width: 0%;
        border: 1px solid #000;
        /* border-radius: 5px; */
        padding: 8px -1px;
    }


    .singletab .tablemainMobile tr th {
        font-size: 10px;
        font-weight: 600;
        text-align: center;
        background-color: #fff;
        border: 1px solid #000;
        /* border-radius: 5px; */
        padding: 8px -1px;
        width: 0%;
    }

    .singletab .tablemainMobile {
        background-color: transparent;
        /* border-collapse: separate; */
        /* border-spacing: 5px; */
    }

    .singletab .tablemainMobile tbody tr:nth-child(odd) {
        background: #004992;
        font-size: 10px;
        text-shadow: 0px 3px 6px #00000029;
        color: #fff;
    }

    .singletab .tablemainMobile tbody tr:nth-child(even) {
        background: #fff;
        font-size: 10px;
        text-shadow: 0px 3px 6px #00000029;
    }

    .singletab .tablemainMobile>:not(caption)>*>* {
        background-color: transparent;
        color: unset;
    }

}

@media(min-width:768px) and (max-width:992px) {

    .singletab .tableMain th,
    .singletab .tableMain td {
        padding: 2px;
    }

    .singletab .tableMain .tablemaintwo tbody td:first-child {
        padding: 10px 0px;
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 600;
        text-wrap: nowrap;

    }

    .singletab .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
        padding: 4px;
        font: normal normal bold 13px / 13px Arial;
    }

    .singletab .tableMain tbody tr:nth-child(even) td:not(:first-child) {
        padding: 4px;
        font: normal normal bold 13px / 13px Arial;
    }
}

@media(min-width:992px) and (max-width:1500px) {

    .singletab .tableMain th,
    .singletab .tableMain td {
        padding: 6px;
    }

    .singletab .tableMain .tablemaintwo tbody td:first-child {
        padding: 10px 0px;
        margin-bottom: 0;
        font-size: 13px;

    }

    .singletab .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
        padding: 4px;
        font: normal normal bold 12px / 12px Arial;
    }

    .singletab .tableMain tbody tr:nth-child(even) td:not(:first-child) {
        padding: 4px;
        font: normal normal bold 12px / 12px Arial;
    }
}

@media(min-width:1500px) and (max-width:2000px) {

    .singletab .tableMain th,
    .singletab .tableMain td {
        padding: 5px;
    }
}