.Mainhometabs {
    background: transparent linear-gradient(119deg, #010943 0%, #0A3144 22%, #000000FA 51%, #0A3144 74%, #010943 100%) 0% 0% no-repeat padding-box;
    position: relative;
}


.Mainhometabs .homeMain .homeNav .nav-link {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 19px;
    font: normal normal bold 18px/21px Arial;
    letter-spacing: 0px;
    color: #FF0000;
}

.Mainhometabs .homeMain .homeNav .nav-link.active {
    background: #278F02 0% 0% no-repeat padding-box;
    border-radius: 19px;
    color: #fff;
}

.Mainhometabs .homeMain .homeNav {
    justify-content: center;
    background: transparent linear-gradient(90deg, #102530 20%, #0a1c25 51%, #000000FA 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: -31px;
    left: 50%;
    transform: translate(-50%, 0px);
    border-radius: 30px;
    padding: 13px 19px;
}

.Mainhometabs .homeMain .datTimesec {
    /* background: transparent linear-gradient(270deg, #FFFFFF 0%, #80808000 100%) 0% 0% no-repeat padding-box; */
    text-align: center;
    padding: 2px;
    margin-top: 17px;
    position: relative;
}

.Mainhometabs .homeMain .datTimesec span {
    font: normal normal bold 20px/23px Arial;
    color: #000;
}

.Mainhometabs .homeMain .datTimesec .dateTimeImg {
    width: 100%;
    height: 34px;
}

.Mainhometabs .homeMain .datTimesec .dateinfo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 4px;
}

.Mainhometabs .homeMain .white-bgggggg {
    text-align: center;
    padding-top: 40px;
    opacity: 1;
    margin-bottom: 5px;
}

.Mainhometabs .homeMain .white-bgggggg p {
    color: #000;
    background: linear-gradient(90deg, #80808000 0%, rgba(230, 230, 230, 1) 25%, rgba(241, 241, 241, 1) 50%, rgba(255, 255, 255, 1) 70%, #80808000 100%);
    width: 50%;
    font-size: 18px;
    font-weight: bold;
    margin: auto;
}


.Mainhometabs .homeMain .tabsss {
    margin-right: 10px;
}

@media screen and (min-width: 0px) and (max-width: 767.98px) {
    .Mainhometabs .homeMain .white-bgggggg {
        padding-top: 9%;
    }

    .Mainhometabs .homeMain .homeNav .nav-link {
        font: normal normal bold 14px/21px Arial;
        padding: 3px 15px;
    }

    .Mainhometabs .homeMain .homeNav {
        padding: 9px 6px;
        width: 100%;
        position: unset;
        transform: none;
    }

    .Mainhometabs .homeMain .white-bgggggg p {
        font: normal normal bold 13px/9px Arial;
        padding: 7px 0px 7px 0px;
        margin-bottom: 12px;
        width: 75%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
    .Mainhometabs .homeMain .white-bgggggg {
        padding-top: 5%;
    }

    .Mainhometabs .homeMain .homeNav .nav-link {
        font: normal normal bold 14px/21px Arial;
    }

    .Mainhometabs .homeMain .homeNav {
        padding: 9px 16px;
    }
}

@media  (min-width: 2500px) and (max-width: 3000px) {


    .Mainhometabs {
        height: 92vh;
    }
}