.fulltab .tableMain th,
.fulltab .tableMain td {
    text-align: center;
    border-bottom: 0;
    font: normal normal bold 25px/29px Arial;
    letter-spacing: 0px;
    color: #000;
    text-shadow: 0px 3px 6px #00000029;
}

.fulltab .tableMain table {
    border-collapse: separate;
    border-spacing: 10px;
}

.fulltab .tableMain tbody tr:nth-child(odd) td {
    background: #fff;
    font: normal normal bold 25px/29px Arial;
    color: #000;
    text-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
}

.fulltab .tableMain tbody tr:nth-child(even) td {
    background: #004992 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font: normal normal bold 25px/29px Arial;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
}

.fulltab .tableMain table tbody td:first-child {
    padding: 10px 0px;
    margin-bottom: 0;
    font-weight: 600;
}

.fulltab .tableMain {
    display: block;
}

.fulltab .tableMainmobile {
    display: none;
}


@media screen and (min-width: 768px) and (max-width: 1200px) {

    .fulltab .tableMain th,
    .fulltab .tableMain td {
        font: normal normal bold 14px/29px Arial;
    }

    .fulltab .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }

    .fulltab .tableMain tbody tr:nth-child(even) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }
}


@media screen and (min-width: 0px) and (max-width: 767.98px) {

    .fulltab .tableMain th,
    .fulltab .tableMain td {
        font: normal normal bold 14px/29px Arial !important;
    }

    .fulltab .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }

    .fulltab .tableMain tbody tr:nth-child(even) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }

    .fulltab .tableMain {
        display: none;
    }

    .fulltab .tableMainmobile {
        display: block;
    }


    .fulltab .tableMainmobile tr td {
        font-size: 10px;
        padding: 8px 2px;
        text-align: center;
        width: 12%;
    }

    .fulltab .tableMainmobile tr th {
        font-size: 13px;
        text-align: center;
    }


    .fulltab .tableMainmobile tbody tr:nth-child(odd) {
        background: #004992;
        font-size: 10px;
        text-shadow: 0px 3px 6px #00000029;
        color: #fff;
    }

    .fulltab .tableMainmobile tbody tr:nth-child(even) {
        background: #fff;
        font-size: 10px;
        text-shadow: 0px 3px 6px #00000029;
    }

    .fulltab .tableMainmobile>:not(caption)>*>* {
        background-color: transparent;
        color: unset;

    }
}