.Resultmodal .modal-content {
    background: #000;
}

.Resultmodal .modal-header {
    text-align: center;
    position: relative;
    border-bottom: 0;
    margin-top: 12px;
}

.Resultmodal .modal-title {
    font: normal normal bold 33px/38px Arial;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FF0303;
}


.Resultmodal .tableMain th,
.Resultmodal .tableMain td {
    text-align: center;
    border-bottom: 0;
    font: normal normal bold 25px/29px Arial;
    letter-spacing: 0px;
    color: #fff;
    text-shadow: 0px 3px 6px #00000029;
    background: #000;
}

.Resultmodal .tableMain .tablemaintwo {
    border-collapse: separate;
    border-spacing: 10px;
    background-color: #fff;
    border-radius: 17px;
}

.Resultmodal .tableMain tbody tr:nth-child(even) td:not(:first-child) {
    background: transparent linear-gradient(180deg, #207501 0%, #103B01 100%) 0% 0% no-repeat padding-box;
    font: normal normal bold 25px/29px Arial;
    color: #000;
    text-shadow: 0px 3px 6px #00000029;
}

.Resultmodal .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
    background: transparent linear-gradient(180deg, #FF0303 0%, #800000 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    font: normal normal bold 25px/29px Arial;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000029;
}

.Resultmodal .tableMain .tablemaintwo tbody td:first-child {
    padding: 10px 0px;
    margin-bottom: 0;
    font-weight: 600;
}

.Resultmodal .x-markMain {
    position: absolute;
    right: 37px;
    top: 26%;
    color: #fff;
}

.Resultmodal .x-markMain .xMarkicon {   
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}

@media screen and (min-width: 0px) and (max-width: 991px) {

    .Resultmodal .tableMain th,
    .Resultmodal .tableMain td {
        font: normal normal bold 14px/29px Arial;
    }

    .Resultmodal .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }

    .Resultmodal .tableMain tbody tr:nth-child(even) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {

    .Resultmodal .tableMain th,
    .Resultmodal .tableMain td {
        font: normal normal bold 14px/29px Arial;
    }

    .Resultmodal .tableMain tbody tr:nth-child(odd) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }

    .Resultmodal .tableMain tbody tr:nth-child(even) td:not(:first-child) {
        font: normal normal bold 14px/29px Arial;
    }
}